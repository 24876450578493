import { Injectable, HostListener } from '@angular/core';

import { Observable } from 'rxjs';
import { ConfirmationComponent } from '../pages/confirmation/confirmation.component';

@Injectable()
export class ConfirmLeaveGuard  {
    @HostListener('window:beforeunload')
  canDeactivate(component: ConfirmationComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.appointmentSaved == false) {
    //    return confirm('You have unsaved changes. Are you sure you want to leave?')
    }
    return true;
  }
}
