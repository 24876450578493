import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as PatientActions from "../actions/patient";

@Injectable()
export class PatientEffects {

    constructor( private actions$: Actions, 
                 private router: Router, 
                 private notification: NotificationService,
                 private loadingService: LoadingService ) {}

    clearStore$ = createEffect(() => { 
        return this.actions$.pipe(
            ofType(PatientActions.ActionTypes.CLEAR_STORE),
            mergeMap(() => {
                this.loadingService.start();

                setTimeout(() => {
                    this.loadingService.stop();
                    this.router.navigate(["/"]); 
                }, 2000)

                return EMPTY;
            }));
    }, { dispatch: false });
}
