<div class="call-to-action py-3">
@if (confirmationRequired) {
    <div>Booking Received</div>
} @else {
    <div>Booking Successful</div>
}
</div>


<div class="inner-container">
    @if (confirmationRequired) {
        
    <div>
        <p>Your appointment is being reviewed by the clinic and you will receive a confimation email once it has been
        accepted.</p>
        <p>If you do not receive a confirmation that your booking has been accespted then please contact the clinic for further information.</p>
    </div>
} @else {
    
    <div>
        Your appointment has been receieved by the clinic and you will receive a confimation email.
    </div>
}

<p class="my-3">If you are on a shared device we recommend you log out otherwise you can now close this page.</p>


    <div class="d-flex justify-content-end my-3">
        <div class="me-3">
            <button mat-flat-button (click)="buttonPress('book-another')"
                >Book another appointment</button>
        </div>
        
        <div>
            <button mat-flat-button (click)="buttonPress('logout')"
                >Logout</button>
        </div>
    </div>

<!--
    <div *ngIf="patient.NewPatient">
        <div class="mt-3">In order to save time on your arrival at the clinic please complete your personal details
            in
            advance:</div>
        <button mat-raised-button class="btn btn-lg m-3" (click)="buttonPress('details')" color="primary">Complete
            Details</button>
    </div>
-->
</div>