import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { initialState, State } from 'src/app/models/patient';

import { NotificationService } from 'src/app/services/notification.service';
import * as fromRoot from '../../store/reducers';
import * as PatientActions from '../../store/actions/patient';

import { AppointmentService } from 'src/app/services/appointment.service';

import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Location } from '@angular/common';
import { AppointmentRequest } from 'src/app/models/booking';
import { StaffService } from 'src/app/services/staff.service';
import { take } from 'rxjs';



@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    standalone: false
})

export class ConfirmationComponent implements OnInit {

  public loggedIn: boolean = false;
  public patient: State = initialState;
  public staff: any;
  public activeAppointment: any;
  public clinic: any;
  public emailClinic: string;
  public appointmentSaved = false;

  public requestedAppointment: AppointmentRequest | undefined;

  constructor(private store: Store<fromRoot.State>,
    private notification: NotificationService,
    private router: Router,
    private appointment: AppointmentService,
    private config: ConfigService,
    private loadingService: LoadingService,
    private staffService: StaffService,
    private _location: Location) {
   
    
    
    this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => this.loggedIn = loggedIn);
    this.store.select(fromRoot.getPatientDetails).subscribe((patient: State) => this.patient = patient);
    this.store.select(fromRoot.getCurrentAppointment).subscribe((appointment: any) => this.activeAppointment = appointment);
    this.config.getStoredClinicDetails().subscribe((clinic: any) => this.clinic = clinic);
  }


  ngOnInit(): void {
    this.store.select(fromRoot.getRequestedAppointment).subscribe(res=>{
      if(!res) { return; }
      this.requestedAppointment = res;
      this.staffService.staffFromIdx(this.requestedAppointment.staffIdx).pipe(take(1)).subscribe(res=>{
        this.staff = res;
      });
      this.loadingService.stop();
    });    

  }


  public prepareBooking(details?: boolean): void {
    this.loadingService.start();
    if(!this.requestedAppointment) { return; }
    const adjustedTime = new Date(this.requestedAppointment.startTime);

    const formattedDate = this.appointment.formatDate(adjustedTime);

    const payload = {
      startTime: `${formattedDate.date} ${formattedDate.time}`,
      staffIdx: this.requestedAppointment.staffIdx,
      duration: +this.requestedAppointment.duration
    }

    this.appointment.clashcheck(payload).subscribe((clashed: any) => {
      if(!this.requestedAppointment) { return; }
      if (!clashed.ok) {
        this.notification.send(clashed.message, "error", 15000);
        this.loadingService.stop();
        this.appointmentSaved = false;
        return;
      } else {
        this.appointment.save(this.requestedAppointment, this.patient, this.staff).subscribe((appInfo) => {
          this.appointmentSaved = true;
          this.loadingService.stop();

          // the patient will receive a payment link trought email so for now we are just redirecting to the complete page 
          if (appInfo.requirePrePayment>0){
               this.router.navigate(['/payment', appInfo.paymentRequestId]);
               return;
          }
          this.router.navigate(['/complete']);
        });
      }
    })
  }

  public saveAppointment(): void {
    this.prepareBooking();
  }

  back(){
    this._location.back();
  }

 
}