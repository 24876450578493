import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { NotificationMessage } from 'src/app/models/notification';
import * as fromNotification from "../../store/actions/notification";

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: false
})
export class SnackbarComponent implements OnInit, OnDestroy {
  private subscription$: Subscription;
  private lastMessage: number = 0;

  constructor(private snackBar: MatSnackBar, private store: Store<fromRoot.State>) { }

  ngOnInit(): void {
    this.subscription$ = this.store.select(fromRoot.getNotificationMessages).subscribe((messages: NotificationMessage[]) => {
      if ( messages && messages[0] ) {
          if ( messages[0].timestamp == this.lastMessage ) return;
          this.display(messages[0]);
      }
    });
  }

  private display(notification: NotificationMessage): void {
    this.lastMessage = notification.timestamp;

    const panelClass = [ notification.type === "success" ? 'success-snackbar' : notification.type === "error" ? 'error-snackbar' : 'default-snackbar' ];

    let snackBarRef = this.snackBar.open(notification.message, "Thanks", {
        duration: notification.duration,
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass
    });

    snackBarRef.afterDismissed().subscribe(() => {
        this.store.dispatch(fromNotification.ShownNotification());
        this.lastMessage = 0;
    });

  }
  
  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
