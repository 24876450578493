import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../store/reducers';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: false
})
export class LoadingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('spinnerElement', { static: false }) spinnerElement: ElementRef;
  private subscription: Subscription;

  constructor( private ngZone: NgZone,
               private renderer: Renderer2,
               private store: Store<fromRoot.State> ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.subscription = this.store.select(fromRoot.getLoading).subscribe((loading: boolean) => {
      loading ? this.show() : this.hide();
    });
  }

  private show(): void {
    if ( !this.spinnerElement ) return;

    this.ngZone.runOutsideAngular(() => {
      this.renderer.addClass(this.spinnerElement.nativeElement, 'wrapper-shown');
    });
  }

  private hide(): void {
    if ( !this.spinnerElement ) return;

    this.ngZone.runOutsideAngular(() => {
      this.renderer.removeClass(this.spinnerElement.nativeElement, 'wrapper-shown');
    });
}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
