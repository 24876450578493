
import { LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/en-GB';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StaffComponent } from './pages/staff/staff.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { PatientDetailsComponent } from './patients/details/details.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FormfieldsComponent } from './patients/formfields/formfields.component';
import { SubheaderComponent } from './components/subheader/subheader.component';
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { reducers, metaReducers } from "./store/reducers/index";
import { EffectsList } from "./store/effects";
import { environment } from "../environments/environment";
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { LoadingComponent } from './components/loading/loading.component';
import { InitializeComponent } from './components/initialize/initalize.component';
import { UnknownClinicComponent } from './components/unknown-clinic/unknown-clinic.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { BrowserModule } from '@angular/platform-browser';
import { CustomFormControlsModule } from './custom-form-controls/custom-form-controls.module';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ConfirmLeaveGuard } from './guards/can-deactivate.guard';

import { WelcomeModule } from './pages/welcome/welcome.module';
import { StaffMemberModule } from './components/staff-member/staff-member.module';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { MinutesToTimePipe } from './pipes/to-mins.pipe';
import { StaffService } from './services/staff.service';
import { TreatmentComponent } from './pages/treatment/treatment.component';

import { CompletedBookingComponent } from './pages/completed-booking/completed-booking.component';
import { FooterComponent } from './components/footer/footer.component';
import { TreatmentCatsComponent } from './pages/treatment-cats/treatment-cats.component';
import { DateProviders } from './material/date.provider';
import { ConfirmationModule } from './pages/confirmation/confirmation.module';

@NgModule({ declarations: [
        AppComponent,
        StaffComponent,
        HeaderComponent,
        PatientDetailsComponent,
        FormfieldsComponent,
        SubheaderComponent,
        SnackbarComponent,
        LoadingComponent,
        InitializeComponent,
        UnknownClinicComponent,
        SideMenuComponent,
        RegistrationComponent,
        AppointmentComponent,
        MinutesToTimePipe,
        TreatmentComponent,
        CompletedBookingComponent,
        FooterComponent,
        TreatmentCatsComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        ConfirmationModule,
        BrowserAnimationsModule,
        MaterialModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        CustomFormControlsModule,
        EffectsModule.forRoot(EffectsList),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
        WelcomeModule,
        StaffMemberModule], providers: [
        DatePipe,
        ...DateProviders,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        ConfirmLeaveGuard,
        StaffService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
