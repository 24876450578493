<div class="call-to-action">
  <button mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>
  </button>
  <div>Login</div>
</div>

<div class="inner-container" (paste)="onPaste($event)">
  <p>
    {{data.message}}
  </p>


  
    <ng-container *ngIf="stage === 0">
      <form autocomplete="off" role="form" (ngSubmit)="login()" class="form form-login">
        <mat-form-field appearance="fill">
          <mat-label>Email Address</mat-label>
          <input matInput type="email" id="email" name="email" required [formControl]='email'>
          <mat-error *ngIf="email.invalid">Please provide a valid email address</mat-error>
        </mat-form-field>
      </form>
    </ng-container>

    <ng-container *ngIf="stage === 1">
      <div>A code has been sent to you. Please check your email</div>
      <div class="d-flex justify-content-center" >
        <form role="form" (ngSubmit)="loginWithCode()" class="form form-login">
          <div class="code-container">
            <input type="number" class="code-input" id="code0" name="code0" [value]="code[0]"  placeholder="0" (keyup)="validateKey($event, 0)"
              (input)="validateInput($event, 0)" (paste)="onPaste($event)"  maxlength="1" min="0" max="9" #code0 autofocus />
            <input type="number" class="code-input" id="code1" name="code1" [value]="code[1]" placeholder="0" (keyup)="validateKey($event, 1)"
              (input)="validateInput($event, 1)" (paste)="onPaste($event)" maxlength="1" min="0" max="9" #code1 />
            <input type="number" class="code-input" id="code2" name="code2" [value]="code[2]" placeholder="0" (keyup)="validateKey($event, 2)"
              (input)="validateInput($event, 2)" (paste)="onPaste($event)" maxlength="1" min="0" max="9" #code2 />
            <span class="code-input"> - </span>
            <input type="number" class="code-input" id="code3" name="code3"  [value]="code[3]" placeholder="0" (keyup)="validateKey($event, 3)"
              (input)="validateInput($event, 3)" (paste)="onPaste($event)" maxlength="1" min="0" max="9" #code3 />
            <input type="number" class="code-input" id="code4" name="code4"  [value]="code[4]" placeholder="0" (keyup)="validateKey($event, 4)"
              (input)="validateInput($event, 4)" (paste)="onPaste($event)" maxlength="1" min="0" max="9" #code4 />
            <input type="number" class="code-input" id="code5" name="code5"  [value]="code[5]" placeholder="0" (keyup)="validateKey($event, 5)"
              (input)="validateInput($event, 5)" (paste)="onPaste($event)" maxlength="1" min="0" max="9" #code5 />
          </div>
        </form>
      </div>
      <div class="col mt-3">
        <button *ngIf="resendTimeOut === 0; else waitMessage" aria-label="No code, try again"
          class="btn btn-link small-link d-flex justify-content-center" (click)="login()">
          No code, try again
        </button>

        <ng-template #waitMessage>
          <div class="text-center helvetica-neue small-font mt-3">
            <p *ngIf="emailSent">Code sent, please remember to check your junk or spam folder.</p>
            <!-- <p class="text-muted">Resend code available in {{ resendTimeOut }}s</p> -->
          </div>
        </ng-template>
      </div>

      <div class="col text-center">
        <ng-template #helpTooltipContent>We've sent you a one time code - wait for this to be sent to you by {{ emailSent
          ?
          'email' : 'sms' }}. You have
          as much time as you need to enter the code.</ng-template>


      </div>
    </ng-container>


  <p *ngIf="data.warning" class="text-danger text-center pb-3 ">{{data.warning}}</p>

  <div class="d-flex justify-content-end mb-3">

    <div>
      <button *ngIf="stage === 0" class="w-100" mat-raised-button color="primary" (click)="login()">Next</button>
      <button *ngIf="stage === 1" class="w-100" mat-raised-button color="primary"
        (click)="loginWithCode()">Login</button>
    </div>
  </div>

</div>