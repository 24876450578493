import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { buildNumber } from 'src/app/build-number';
@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    standalone: false
})
export class SideMenuComponent implements OnInit {
  @Input() user;
  readonly buildNumber = buildNumber;
  public loggedIn: boolean;
  public loading: boolean = false;
  public userName: string = ""
  public staffDetails;




  constructor(
    private LoginService: LoginService) { }

  ngOnInit(): void { }


  public logout(): void {
    this.LoginService.logout();
  }

}

