import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { MaterialModule } from "src/app/material/material.module";
import { ConfirmationComponent } from "./confirmation.component";
import { PostBookingInfoModule } from "src/app/components/post-booking-info.module";


@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      PostBookingInfoModule
    ],
    declarations: [
        ConfirmationComponent
    ],
    providers: [],
    exports:[
    ]
})
export class ConfirmationModule { }

