import { Injectable } from '@angular/core';

export interface Theme  {
    primaryLight: string,
    primaryDark: string
  }

@Injectable(
    {providedIn: 'root'}
)
export class ThemeService {

    setTheme(theme: Theme){
        document.documentElement.style.setProperty('--primary-light', theme.primaryLight);
    }


}