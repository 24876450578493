import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

import { Store } from "@ngrx/store";
import * as fromRoot from '../store/reducers';
import * as PatientActions from '../store/actions/patient';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: DataService,  private store: Store<fromRoot.State>) { }

  public authenticate(email: string, code?: string): Observable<any> {
    const payload: any = { email };

    if ( code ) payload.code = code;

    return this.http.post("/public/authenticate", payload);
  }



  public logout(): void {
    this.store.dispatch(PatientActions.ClearStore());
  }
}
