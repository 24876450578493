import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-unknown-clinic',
    templateUrl: './unknown-clinic.component.html',
    styleUrls: ['./unknown-clinic.component.scss', '../../pages/welcome/welcome.component.scss'],
    standalone: false
})

export class UnknownClinicComponent implements OnInit {
  public readonly logo = "https://cdn.multiclinic.co.uk/demo/top-logo.png";

  constructor() { }

  ngOnInit(): void {
    
  }

}
