import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { mapToCanActivate } from '@angular/router';
import { TreatmentsService } from './treatments.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor( private http: DataService, private treatmentsService: TreatmentsService ) {}

  public sortStaff(treatmentCategories: any, staff: any): any {
    const treatmentTypes = treatmentCategories.slice();

    staff.forEach((staffMember: any) => {
      treatmentCategories.forEach((category: any, index: number) => {
        if ( !treatmentTypes[index].staff ) treatmentTypes[index].staff = [];

        treatmentTypes[index].selected = false;

        if ( staffMember.treatFromCat === category.idx ) {
          treatmentTypes[index].staff.push( { ...staffMember, selected: false } );
        }
      })
    })

    return this.filterTreatmentCategories(treatmentTypes, "staff");
  }

  public filterTreatmentCategories(treatmentCategories: any, field: string = 'treatments'): any {
    return treatmentCategories.filter((category: any) => category[field].length);
  }


  public getFilteredBookingData(treatmentTypeIdx: number){
    return this.treatmentsService.getTreatmentCategories().pipe(
      map(cats => cats.filter(cat=> (cat.idx == treatmentTypeIdx)))
    )
  }

  public getBookingData(): Observable<any> {
    return combineLatest([ this.treatmentsService.getTreatmentCategories(), this.getStaff() ]);
  }



  public getStaff(): Observable<any> {
    return this.http.get('/public/staff');
  }

  public getAppointment(payload: any): Observable<any> {
    payload.selectOn = "staff";
    payload.cancelled = false;

    return this.http.post("/public/diary/appointments", payload);
  }
/*
  public updateSelectedStaff(treatmentCategories: any, categoryIndex: number, staffIndex: number): any {
    treatmentCategories[categoryIndex].staff[staffIndex].selected = !treatmentCategories[categoryIndex].staff[staffIndex].selected;
  }
*/
  public updateSelectedTreatments(treatmentCategories: any): any {
    const updatedTreatmentCategories = treatmentCategories.map((category: any) => {
      const staffSelected = category.staff.find((staffMember: any) => staffMember.selected === true);

      return { ...category, selected: !!staffSelected }
    })

    return updatedTreatmentCategories;
  }

  public createAvailableEvents(availabilities: any[], events: any[], diarySettings: any, bookedDates: any, selectedStaff: any): any[] {
    events = events.filter((event: any) => event.meta.class !== "available");

    availabilities.forEach((availability: any, index: number) => {
      const minutes = ( availability.endTime - availability.startTime ) / 60000;
      const increment = diarySettings.increment;

      for ( let current = 0; current < minutes; current += increment ) {
        const startTime = new Date(availability.startTime.getTime());
        const endTime = new Date(availability.startTime.setMinutes(availability.startTime.getMinutes() + increment));

        const found = bookedDates.includes(new Date((new Date(startTime.getTime()).setMilliseconds(0))).getTime())
        const today = new Date();

        if ( !found && ( today < startTime ) ) {
          events = [...events, {
            id: index,
            start: startTime,
            end: endTime,
            meta: { 
              staff: selectedStaff,
              class: "available" 
            },
            title: selectedStaff.firstname + " " + selectedStaff.lastname
          }];
        } 
      }
    })
    
    
    return events;
  }

  public mergeBookedAppointments(appointments: any[], patient: any): any[] {
    let mergedAppointments:any[] = [];

    const sortedAppointments = appointments.sort((appointmentA, appointmentB) => new Date(appointmentA.startTime).getTime() - new Date(appointmentB.startTime).getTime());

    sortedAppointments.forEach((appointment: any) => {
      if ( appointment.status !== 'Cancelled' && ( appointment.patientIdx !== +patient.idx ) ) {
        if ( !mergedAppointments.length ) mergedAppointments.push(appointment);
        else {
          const appointmentStartTime = new Date(appointment.startTime);
          let found = false;

          mergedAppointments = mergedAppointments.map((mergedAppointment: any) => {
            const startTime = new Date(mergedAppointment.startTime);
            const startTimeCopy = new Date(startTime.getTime());
            const endTime = new Date(startTimeCopy.setMinutes(startTimeCopy.getMinutes() + mergedAppointment.duration));

            if ( endTime.getTime() === appointmentStartTime.getTime() ) {
              found = true;

              return {
                ...mergedAppointment,
                duration: mergedAppointment.duration + appointment.duration
              }
            } else return mergedAppointment;
          })

          if ( !found ) mergedAppointments.push(appointment);
        }
      }
    })

    sortedAppointments.forEach((appointment: any) => {
      if ( appointment.patientIdx === +patient.idx ) mergedAppointments.push(appointment);
    })

    return mergedAppointments;
  }

  public createBookedEvents(appointments: any[], events: any, loggedIn: any, patient: any): any[] {

    const mergedAppointments = this.mergeBookedAppointments(appointments, patient);

    mergedAppointments.forEach((appointment: any, index: number) => {
      const startTime = new Date(appointment.startTime);
      const startTimeCopy = new Date(startTime.getTime());
      const endTime = new Date(startTimeCopy.setMinutes(startTimeCopy.getMinutes() + appointment.duration));

      if ( appointment.status !== 'Cancelled' ) {
        let metaClass: string = "booked";
        let title: string = "Booked";
  
        if ( loggedIn ) {
          metaClass = appointment.patientIdx === +patient.idx ? 'own-appointment' : 'booked';
          title = appointment.patientIdx === +patient.idx ? 'My Appointment' : 'Booked';
        }
  
        events = [...events, {
          id: index,
          start: startTime,
          end: endTime,
          meta: { 
            class: metaClass,
            appointment
          },
          title
        }];
      }
    })

    return events;
  }
}
