<form [formGroup]="form">
    <div *ngIf="question.fieldType === 'TitleArea'" class="form-group">
        <h4 [id]="question.fieldKey">{{ question.fieldKey }}</h4>
    </div>

    <div *ngIf="question.fieldType === 'SubTitleArea'" class="form-group">
        <h5 [id]="question.fieldKey">{{ question.fieldKey }}</h5>
    </div>

    <div *ngIf="question.fieldType === 'DatePicker'" class="form-group">
        <mat-form-field style="width:100%" >
            <mat-label>{{ question.label }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="question.fieldKey" [required]="question.required" [id]="question.fieldKey" [placeholder]="question.placeholder ? question.placeholder : question.label">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div *ngIf="question.fieldType === 'RangeSlider'" class="form-group">
        <label>{{question.label}}</label>
        <div class="d-flex justify-content-between">
            <mat-slider aria-label="unit(s)" 
                [id]="question.fieldKey" 
                [min]="question.options ? question.options[0] : 0" 
                [max]="question.options ? question.options[1] : 10" 
                [step]="question.options ? question.options[2] : 1" 
                showTickMarks 
                discrete
                style="width: '85%';">
            <input matSliderThumb  [formControlName]="question.fieldKey"/>
        </mat-slider>
<!--           <input type="text" [value]="form.controls[question.fieldKey].value ? form.controls[question.fieldKey].value : 0" disabled class="form-control" style="width: '10%';"> -->
        </div>
    </div>

    <div *ngIf="question.fieldType === 'TextboxQuestion' || question.fieldType === 'Patientchooser'" class="form-group">
        <mat-form-field style="width:100%" >
            <mat-label>{{ question.label }}</mat-label>
            <input matInput [id]="question.fieldKey" [formControlName]="question.fieldKey" [placeholder]="question.placeholder ? question.placeholder : question.label" [required]="question.required" />
            <mat-error *ngIf="form.controls[question.fieldKey].hasError('required')">Please provide your {{ question.label.toLowerCase() }}</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="question.fieldType === 'Checkbox'" class="form-group">
        <mat-checkbox type='checkbox' class='checkbox' [id]="question.fieldKey" [formControlName]="question.fieldKey">
            {{question.label}}
        </mat-checkbox>
    </div>
    
    <div *ngIf="question.fieldType === 'TextArea'" class="form-group">
        <mat-form-field class="w-100">
            <label for="">{{question.label}}</label>
            <textarea 
            [id]="question.fieldKey" 
            [formControlName]="question.fieldKey" 
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
    </div>

    <div *ngIf="question.fieldType === 'InfoBox'" class="form-group">
        <div class="alert alert-info">{{question.fieldKey}}</div>
    </div>
    
    <div *ngIf="question.fieldType === 'DropdownQuestion' || question.fieldType === 'Vendorchooser'" class="form-group">
        <mat-form-field style="width:100%">
            <mat-label style="font-size: 1.3rem">{{question.label}}</mat-label>
            <mat-select [id]="question.fieldKey" [formControlName]="question.fieldKey" [required]="question.required">
              <mat-option disabled selected value=""></mat-option>
              <mat-option *ngFor="let option of question.options" [value]="option.idx ? option.idx : option">
                {{ option.text ? option.text : option }} <span *ngIf="option.info" class="ps-2 text-muted text-small">{{ option.info }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls[question.fieldKey].hasError('required')">Please select {{ question.label }}</mat-error>
        </mat-form-field>

        
    </div>

    <div *ngIf="question.fieldType === 'GPchooser'" class="form-group" class="mt-3">
        <label class="label">{{question.label}}</label>
        <generic-chooser 
        [question]="question" 
        [formControlName]="question.fieldKey" 
        ></generic-chooser>
    </div>




</form>
