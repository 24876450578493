import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormField } from 'src/app/models/form';

@Component({
    selector: 'app-formfields',
    templateUrl: './formfields.component.html',
    styleUrls: ['./formfields.component.scss'],
    standalone: false
})
export class FormfieldsComponent implements OnInit {
  @Input() question: FormField;
  @Input() form: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    console.log(this.form);
    if ( this.question.fieldType === "GPchooser" ) {
      this.question.options = this.question.options && this.question.options.filter((gp: any) => gp.text);
    }
  }

}
