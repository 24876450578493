<div class="call-to-action">
    <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div>Select treatment</div>
</div>

<div class="inner-container">
    <div class="row mt-3">
        <div *ngFor="let type of treatments.treatments" class="col-sm-6" (click)="selectTreatment(type)">
            <div class="mx-2 mb-2 treatment-type d-flex flex-column justify-content-between">
                <div class="row w-100">
                    <div class="col-sm-12 text-center fw-bold">{{ type.name }}</div>
                </div>
                <div class="row w-100">
                    <div class="col-sm-6 text-center">{{ type.defaultDuration }} minutes</div>
                    <div class="col-sm-6 text-center">{{ type.defaultPrice | currency : 'GBP' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>