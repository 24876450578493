import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from "@ngrx/store";
import * as fromRoot from '../../store/reducers';
import * as PatientActions from '../../store/actions/patient';
import { State } from 'src/app/models/patient';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  @Output() triggerSideMenu: EventEmitter<any> = new EventEmitter();
  @Input("sideNavOpened") sideNavOpened: boolean = false;

  public loggedIn: boolean = false;
  public patient: State;
  public clinicLogo: string = "";
  public clinicName: string = "";

  public readonly logo = "https://cdn.multiclinic.co.uk/demo/top-logo.png";

  constructor(
    private store: Store<fromRoot.State>,
    private config: ConfigService,
    private router: Router) {
    config.getClinicName().subscribe(res => {
      console.log('Clinic Name: ', res);
      this.clinicName = res || "";
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes['sideNavOpened'] ) {
      this.sideNavOpened = changes['sideNavOpened'].currentValue;
    }
  }

  ngOnInit(): void {
    this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => this.loggedIn = loggedIn );
    this.store.select(fromRoot.getPatientDetails).subscribe((patient: State) => this.patient = patient );

    this.config.getStoredClinicDetails().subscribe((clinic: any) => {
      this.clinicLogo = (clinic && clinic.logo) ? clinic.logo : this.logo;
    })
  }

  public logout(): void {
    this.store.dispatch(PatientActions.ClearStore());
  }
}
