import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {


  constructor(private store: Store<fromRoot.State>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.store.select(fromRoot.isPatientLoggedIn).pipe(map((loggedIn: boolean) => {
        if (loggedIn) {
          console.log("[guard] auth says ok" );
          return true;
          
        } 
        console.log("[guard] auth says no" );
        this.router.navigate(['/']);
        return false;
    }));
  }
}
