<div class="call-to-action">
    
    <div>Have you visited us before?</div>
</div>

<div class="inner-container">

<div class="login-item">
    <div class="row my-5">
        <div class="col-sm-5 mb-2">
            <button mat-raised-button color="primary" (click)="redirect(['login'])" class="w-100">Current
                patient</button>
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-5">
            <button mat-raised-button color="primary" (click)="redirect(['registration'])" class="w-100">New
                patient</button>
        </div>
    </div>
    <p class="text-muted">If you have previously registered with the clinic in person you should select 'Current
        patient'.</p>
</div>