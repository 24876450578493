import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

export interface DynamicDropdownOption{
  idx: number;
  text: string;
  info?: string
}

@Injectable({
  providedIn: 'root'
})
export class DynamicAutoCompleteService {
    constructor(
        private http: DataService
    ) {}

    get(fieldIdx: number) : Observable<DynamicDropdownOption[]>{
      return this.http.get<DynamicDropdownOption[]>(`/public/forms/dynamicoptions/${fieldIdx}`);
    }

}
