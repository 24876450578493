import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { VisitedBeforeComponent } from 'src/app/components/visited-before/visited-before.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LastBookedComponent } from '../last-booked/last-booked.component';
import { StaffMemberModule } from 'src/app/components/staff-member/staff-member.module';

@NgModule({ 
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild([]),
        FormsModule,
        ReactiveFormsModule,
        StaffMemberModule
    ],    
    declarations: [
        WelcomeComponent,
        VisitedBeforeComponent,
        LoginComponent,
        LastBookedComponent
    ],
    exports: [
        WelcomeComponent,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        StaffMemberModule,
        RouterModule]
    })
export class WelcomeModule { }

