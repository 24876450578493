

<div class="header-row row">
        <h2 class="text-center">Your last booked appointment</h2>
</div>
    
<div class="mt-2">
        <app-staff-member [selectedStaff]="lastAppointmentStaff"></app-staff-member>
        <ul class="text-center">
            <li><strong>{{ this.lastTreatment?.name }}</strong></li>
            <li>{{ this.lastTreatment?.defaultDuration }} minutes</li>
            <li>{{ this.lastTreatment?.defaultPrice | currency : 'GBP' : 'symbol'}}</li>
        </ul>
        <div class="login-item">
            <div class="row my-5">
                <div class="col-sm-6 mb-2">
                    <button mat-raised-button color="primary" (click)="bookSameAppointment()" class="w-100">Book again</button>
                </div>

                <div class="col-sm-6">
                    <button mat-raised-button color="primary" (click)="newBooking()" class="w-100">Something else</button>
                </div>
            </div>
        </div>
</div>