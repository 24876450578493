import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import * as fromRoot from '../store/reducers';
import * as authActions from '../store/actions/config';
import * as PatientActions from '../store/actions/patient';
@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
constructor( private notification: NotificationService, 
    private loadingService: LoadingService,
    private router: Router,
    private store: Store<fromRoot.State> ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
            let errorMessage: string = "";
            console.log(error);
            if (error.error instanceof ErrorEvent) {
                errorMessage = `${error.statusText} - ${error.error.message}`;
            } else {
                errorMessage = `${error.statusText} - ${error.error.message}`;
            }

            this.loadingService.stop();

            if ( error.error.message == "undefined" || error.error.message == undefined ) {
                return EMPTY;
            }
            if (error.status == 401 && error.url && (error.url.indexOf('authenticate') == -1)) { //401 go home
                this.store.dispatch(PatientActions.ClearStore());
                this.router.navigate(["/"]);
                return EMPTY;
            }

            //this.notification.send(errorMessage, "error");
           
            return next.handle(request);
        }))
    }
}