import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ActionTypes } from "../actions/booking";
import { tap, withLatestFrom } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";


@Injectable()
export class StaffMemberTreatmentEffects {
    saveStaffMember = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.SET_STAFF, ActionTypes.SET_TREATMENT),
        withLatestFrom(this.store.select('SET_STAFF', 'SET_TREATMENT')),
        tap(([action, staff]) => {
            console.log(action)
            localStorage.setItem('staff', staff)
        })
    ),
        {dispatch: false});

    constructor(private actions$: Actions, private store: Store<{SET_STAFF: any}>) {}
}