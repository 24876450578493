<div class="call-to-action py-3">
    <div>Select treatment type</div>
</div>

<div class="inner-container">
<div *ngFor="let cat of treatments" class="pt-3" color="accent" class="treatment-cat-box action-box" (click)="navigateStaff(cat.idx)">
    <div  class="d-flex w-100 text-start justify-content-between align-items-center treat-cat-bar">
        <span class="text-large">{{cat.name}}<mat-icon class="v-middle">arrow_right_alt</mat-icon></span> 
        <div class="not-mobile">
            <mat-chip-set  aria-label="Treatment Catagory selection">
                <mat-chip 
                class="text-small" 
                *ngFor="let treatment of cat.treatments"
                (click)="navigateStaff(cat.idx, treatment.idx)"><span class="text-small">{{treatment.name}}</span></mat-chip>
            </mat-chip-set>
        </div>
</div>
    
    <p class="p-2">{{cat.description}}</p>
</div>
</div>