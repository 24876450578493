<div  class="button" mat-button [matMenuTriggerFor]="userMenu">
  <!--<i class="material-icons">face</i> -->
  <img src="assets/images/mc-circle.png" class="hover-highlight"/>
    </div>
  <mat-menu #userMenu="matMenu" class="px-2">
      <div class="profile-area d-inline-flex flex-column justify-content-center align-items-center">
        <div class="username fw-bold">{{user.FirstName}} {{user.LastName}}</div>
        <div class="username fw-light">{{user.EMail}}</div>
      </div>
      <button mat-menu-item [routerLink]="'/details'">
        <mat-icon>account_box</mat-icon>
        User profile
      </button>
  
  
      <button  mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Log out
      </button>
      <div class="text-muted text-center small">{{buildNumber}}</div>
  
   
      
  
  </mat-menu>
  




