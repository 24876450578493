<div class="call-to-action">
    <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div>Check appointment details</div>
</div>

<div class="inner-container">
    <app-appointment-info [appointment]="requestedAppointment" ></app-appointment-info>
    <div *ngIf="loggedIn && !appointmentSaved">
        <div class="d-flex justify-content-end my-3">
            
            <div>
                <button mat-raised-button color="primary" class="w-100" (click)="saveAppointment()">Request Appointment
                </button>
            </div>
        </div>
    </div>
</div>