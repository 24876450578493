import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, iif } from 'rxjs';
import * as fromRoot from '../store/reducers';

@Injectable({
    providedIn: 'root'
})
export class ClinicSetGuard {
    loggedIn: boolean

    constructor(private config: ConfigService,
        private store: Store<fromRoot.State>,
        private router: Router) { }

    canActivate() {
        const cookies = document.cookie
        .split(';')
        .reduce((res, c) => {
          const [key, val] = c.trim().split('=').map(decodeURIComponent)
          try {
            return Object.assign(res, { [key]: JSON.parse(val) })
          } catch (e) {
            return Object.assign(res, { [key]: val })
          }
        }, {});

       // const clinicFromCookie = /clinic=(.*)\;/.exec(cookie);

       

        const clinicFromCookie =  cookies['clinic']  ? cookies['clinic'] : undefined ; 


        console.log("[guard] can activate", cookies);

        console.log("[guard] can activate==", clinicFromCookie);

        return this.config.getClinicName().pipe(map(res => {
            if (res) { //we have a clinic, check if we are logged-in.
                this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => {
                    if (!loggedIn) {
                        console.log("[guard] clinic loads config");
                        this.config.loadConfig(res);
                    }
                    return true;
                });
                return true;
            } else if (clinicFromCookie) {
                this.config.setClinicName(clinicFromCookie);
                return true;
            } else {
                console.log("[guard] idk this clinic");
                this.router.navigate(['/unknown']);
                return false;
            }
        }));
    }


}
