import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ValueAccessorBase } from "../value-accessor-base";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { DynamicAutoCompleteService, DynamicDropdownOption } from "src/app/services/dynamic-autocomplete.service";
import { MatOption } from "@angular/material/core";

@Component({
    selector: "generic-chooser",
    templateUrl: "./generic-chooser.component.html",
    styleUrls: ["./generic-chooser.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GenericChooserComponent,
            multi: true,
        },
    ],
    standalone: false
})
export class GenericChooserComponent extends ValueAccessorBase<any> implements OnInit, OnChanges {
  @Input() question: any;
  @ViewChild('inputRef', {static: true}) inputRef: ElementRef<HTMLInputElement>;

  @ViewChild('auto') auto: MatAutocomplete;

  private allOptions: DynamicDropdownOption[] = [];
  public filteredOptions: Observable<DynamicDropdownOption[]>;

  resultsListPos: any;
  @Output() valueChange = new EventEmitter()
  @Input() externalValue;

  public autoCompleteTextInput = new FormControl("");

  constructor(
    private dataService: DynamicAutoCompleteService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
    super();
  }

  ngOnInit() {

    this.filteredOptions = this.autoCompleteTextInput.valueChanges.pipe(
      map(value => {
        if (typeof value != "string") { value = ""; }
        let filtered = this._filter(value || '');
        if (filtered.length < 5) {
          filtered.push({ idx: 0, text: "NOT LISTED", info: "" });
        }
        return filtered;
      })


    );


    this.updateOptions();

    }
    ngOnChanges(changes: SimpleChanges): void {
      if (changes['externalValue']){
        console.log("[choose] change from external value ", this.externalValue);
        this.setValue(this.externalValue);
      }
    }


    setAutoCompleteValue(value): void{
      setTimeout(() => {

        //this.inputRef.nativeElement.value = 'Bupa'; // Won't work without this line
       // debugger;

        const options = this.auto.options.toArray();
        console.log(`[choose] searching options for ${value}`, options);
        const v = options.find(f => { return +f.value.idx === +value; })
        if (!v){ return; }
        const setValue = v ? v.value : "";
        this.autoCompleteTextInput.setValue(setValue);
        /*
        if (options[0]){
          console.log("[choose] go penguin ==>", this.question, options);
          this.autoCompleteTextInput.setValue(options[0].value)
        }else{
          console.log("[choose] no option ==>", this.question, this.auto);
        }
        */
      }, 5);
    }

    private updateOptions(value = null){
      console.log(this.question);
    this.dataService.get(this.question.idx).subscribe(res => {
      this.allOptions = res;
      this.autoCompleteTextInput.setValue(""); //this triggers an update to give us all the values
      if (value) {
        this.value = value;
        //const textValue = this.allOptions.find(f=> { return f.idx == value; }).text
        this.setAutoCompleteValue(value);
      }


  });
}


  displayFn(data: any): string {
    let ret = data && data.text ? data.text : '';
    ret += data && data.info ? ` - ${data.info}` : '';
    return ret;
  }

  updateValue(event) {
    console.log("[choose] change ", event);
    this.value = event.option.value.idx;
    this.valueChange.emit(this.value);
  }


  private _filter(value: string): DynamicDropdownOption[] {
    console.log("[choose] why bad", value);

    if (!value || value == "") {
      return this.allOptions;
    }

    const filterValue = value.toLowerCase() || "";
    return this.allOptions.filter(option => (option.text.toLowerCase().includes(filterValue) || option?.info?.toLowerCase().includes(filterValue) ));
  }

  setValue(v) {
    console.log(`[choose] will update and choose ${v} eeek`);
    this.updateOptions(v);
  }

}
