import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  private startOfWeekInUnix: any;

  constructor() { }

  private compareNumbers(a: number, b: number) {
    return a - b;
  }

  private orderArray(arrayToOrder: any) {
    return arrayToOrder.sort(this.compareNumbers);
  }

  private stringArrayToNumber(arrayToConvert: any) {
    return arrayToConvert.map((time: any) => Number(time));
  }

  private getWeekStartUnixTime(date: any) : number{
    const currentDate = new Date(date);

    let weekDelta = currentDate.getDay();

    if (weekDelta == 0) { 
      weekDelta = 7;
    }

    const firstDayOfWeek = currentDate.getDate() - weekDelta + 1;
    
    this.startOfWeekInUnix = new Date(currentDate.setDate(firstDayOfWeek)).setHours(0, 0, 0);

    return this.startOfWeekInUnix;
  }

  private convertArray(arrayToConvert: any) {
    let orderedArray = [];
    orderedArray = this.stringArrayToNumber(arrayToConvert);
    orderedArray = this.orderArray(orderedArray);

    return orderedArray;
  }

  private findStartAndEndTime(available: any[], increment: number): any {
    const availabilities: any[] = [];

    let index: number = 0;
    let startTime: number = available[0];
    let endTime: number = 0;
    
    while ( index < available.length ) {
      if ( available[index + 1] - available[index] <= increment ) {
        endTime = available[index + 1];
      } else {
        endTime = available[index];
        availabilities.push({ startTime, endTime });
        startTime = available[index + 1];
      }

      index++;
    }

    return availabilities;
  }

  private convertDateToISO(time) {
    const secondsInMinute = 60;
    const milisecondsInSecond = 1000;

    const timeUnix = this.startOfWeekInUnix + (time * secondsInMinute * milisecondsInSecond);

    return new Date(timeUnix).toISOString();
  }

  public dateToAvailMins(date: DateTime): number{
    let secs = date.toMillis();
    secs = secs - this.getWeekStartUnixTime(date);
    return Math.floor(secs / 60000);
  }

  public availMinsToDate(week: Date, mins: number){
    const timeUnix = this.getWeekStartUnixTime(week) + (mins * 60 * 1000);
    return new Date(timeUnix);
  }

  public convertAvailability(availability: any[], date: Date = new Date(), increment: number = 15):any[] {
      this.getWeekStartUnixTime(date);
  
      if (!availability) return [];
  
      const allAvailableSlots:any[] = [];
      availability.forEach((element: any) => {
        if (element.available[0] != '') {
  
          const orderedArray = this.convertArray(element.available);
          const startEndArray = this.findStartAndEndTime(orderedArray, increment);
          
          startEndArray.forEach((startEnd: any) => {
            allAvailableSlots.push({
              startTime: new Date(this.convertDateToISO(startEnd.startTime)),
              endTime: addMinutes(new Date(this.convertDateToISO(startEnd.endTime)), increment),
              staffIdx: element.staffIdx
            });
          })
        }
      });
  
      return allAvailableSlots;
    }
}
