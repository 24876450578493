import { Component, HostListener, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import * as fromRoot from '../../store/reducers';
import { Store } from '@ngrx/store';
import { TreatmentsService } from 'src/app/services/treatments.service';
import { Treatment, Treatments } from 'src/app/models/treatment';
import { Router } from '@angular/router';

@Component({
    selector: "app-treatment-cats",
    templateUrl: "./treatment-cats.component.html",
    styleUrls: ["./treatment-cats.component.scss"],
    animations: [
        trigger("slideInOut", [
            transition(":enter", [
                style({ height: "0", opacity: 0 }),
                animate("300ms ease-in-out", style({ height: "*", opacity: 1 })),
            ]),
            transition(":leave", [
                animate("300ms ease-in-out", style({ height: "0", opacity: 0 })),
            ]),
        ]),
    ],
    standalone: false
})


  export class TreatmentCatsComponent implements OnInit {

        constructor (private treatmentsService: TreatmentsService, private router: Router ) {}

        public treatments: Treatments[];
        ngOnInit(): void {
            this.treatmentsService.getTreatmentCategories().subscribe(res=>{
                this.treatments = res;
            })
            

  }

  navigateStaff(cat: number, treatment: number = 0){
    this.router.navigate(['staff', cat, treatment]);
  }
}

