import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PatientService } from 'src/app/services/patient.service';
import * as ConfigActions from "../actions/config";
import { ActionWithPayload } from '../reducers';
import { State } from '../reducers/config';
import { StaffService } from 'src/app/services/staff.service';
import { SiteLinks } from 'src/app/models/config';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';

@Injectable()
export class ConfigEffects {

    constructor( private actions$: Actions, 
                 private router: Router,
                 private config: ConfigService,
                 private loadingService: LoadingService,
                 private PatientService: PatientService ) {}

    loadConfig$ = createEffect(() => { 
        return this.actions$.pipe(
            ofType(ConfigActions.ActionTypes.LOAD),
            map((action: ActionWithPayload) => {
                this.loadingService.start();
                return action;
            }),
            mergeMap((action: ActionWithPayload) => {
                return this.config.load(action.payload.clinicName).pipe(
                    catchError((error, caught)=>{
                        console.log("config load error ", error);
                        this.router.navigate(['unknown']);
                        return EMPTY;
                    }),
                    map((config: { jwt: string, error: string, config: SiteLinks }) => {
                    if ( config.jwt ) {
                        console.log("we have valid jwt");
                        this.PatientService.clearActiveStore();
                        this.config.setJWT( config.jwt );
                        this.config.setClinicName( action.payload.clinicName );
                        this.config.setLinks( config.config );
                        this.config.getClinicDetails().subscribe((clinics: any) => {
                            this.config.getDiarySettings(clinics[0].idx).subscribe((settings: any) => {
                                const payload: State = { 
                                    jwt: config.jwt,
                                    clinic: clinics[0],
                                    settings: settings
                                };
                                this.config.setConfig(payload);
                                this.loadingService.stop();
                            })
                        })
                    }else{
                        throw new Error("Fails");
                        
                    }
                }))
            }));
    }, { dispatch: false });
}
