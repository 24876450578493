<div class="call-to-action">
    <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div>Select staff member</div>
</div>

<div class="inner-container">
    <div class="treatments">
            <h4 class="treatment__name">The <strong>{{ selectedTreatment?.name }}</strong> team</h4>
            <div class="treatment__underline"></div>
            <div class="treatment__staff w-100">
                <div *ngFor="let member of staffList" class="treatment__staff--member">
                    <div (click)="selectStaff($event, member, selectedTreatment?.idx)">
                        <app-staff-member [selectedStaff]="member" [page]="'home'"></app-staff-member>
                    </div>
                

                </div>
                <div *ngIf="staffList ? staffList.length > 1 : false" class="treatment__staff--member">
                    <div (click)="selectAllStaffForTreatment(selectedTreatment)">
                        <app-staff-member [selectedStaff]="{firstname: 'Any', lastname: 'Practioner', profReg: 'from the '+selectedTreatment?.name+' team'}" [page]="'home'"></app-staff-member>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>

