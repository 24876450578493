import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldType, FormField, onlineDetails } from 'src/app/models/form';
import { FormService } from 'src/app/services/form.service';
import { Store } from "@ngrx/store";
import * as fromRoot from '../../store/reducers';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { ConfigService } from 'src/app/services/config.service';
import { LoginService } from 'src/app/services/login.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    standalone: false
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public details: FormField[] = [];
  public fields: string[] = ["DefaultStaffIdx", "DefaultClinicIdx", "HearAboutUsIdx", "GPIdx", "accountIdx"];
  public sections: FormField[] = [];
  public booking: any;
  private patient: any = {};
  public patientFromLink: boolean = false;
  public patientIdxFromLink: number;
  public loggedIn: boolean = false;
  private formLoaded: boolean = false;
  public mobile: boolean;
  public newPatient: boolean = false;

  private params$: Subscription;
  private booking$:Subscription;
  private loggedIn$: Subscription;
  private patient$: Subscription;

  constructor(private formService: FormService,
    private config: ConfigService,
    private store: Store<fromRoot.State>,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private loginService: LoginService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.setMobile(window.innerWidth);

    this.booking$ = this.store.select(fromRoot.getBookingState).subscribe((booking: any) => {
      this.booking = booking;
    })

    this.loggedIn$ = this.store.select(fromRoot.isPatientLoggedIn).subscribe(loggedIn => {
      console.log("[logged in] r we", loggedIn)
      this.loggedIn = loggedIn;

      if (this.patientFromLink && this.loggedIn) {
        this.loadDetails(this.patientIdxFromLink);
      } else if (this.loggedIn){
        this.loadDetails();
      } 
    });

    
  }


  ngOnDestroy(): void {
   // this.params$.unsubscribe();
    this.booking$.unsubscribe();
    this.loggedIn$.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setMobile(event.target.innerWidth);
  }

  private setMobile(windowWidth: number): void {
    this.mobile = windowWidth < 992;
  }

  public loadDetails(patientIdx: number = 0): void {
    if (patientIdx) {
      // This is when the patient is redirected to the details page
      // And we have a patientIdx being sent with the link.
      // The same thing should happen, the patient is required to log in
      // And with their valid token, they will get their details.
    }

    if (!this.formLoaded) {
      this.loadingService.start();
      this.patientService.getPatient().subscribe((patientForm: any) => {
        console.log("[logged in] pf", patientForm)
        this.patient = patientForm;
        this.loadingService.stop();
        this.buildForm(patientForm);
        this.formLoaded = true;
      })
    }
  }

  public loadOptions(): void {
    this.config.getFormOptions().subscribe((options: any) => {
      this.details = this.details.map((question: FormField) => {
        if (this.fields.includes(question.fieldKey)) {
          return {
            ...question,
            options: options[question.fieldKey]
          }
        }

        return question;
      })
    })
  }

  private buildForm(patientForm: any = null): void {

    const establishedForm = this.formService.prepare(onlineDetails, patientForm);
    this.form = establishedForm[0];
    this.details = establishedForm[1];

    this.sections = this.details.filter((field: FormField) => field.fieldType === FieldType.TitleArea);

    this.loadOptions();
  }

  public save(): void {
    if (!this.form.valid) return;

    const sectionTitles = this.sections.map((section: any) => section.fieldKey);

    let formFields = {};
    for (let field in this.form.value) {
      if (!sectionTitles.includes(field)) formFields[field] = this.form.value[field];
    }

    const create = this.loggedIn ? false : true;
    const patientIdx = this.loggedIn ? this.patient.idx : 0;

    this.patientService.save(formFields, create, patientIdx).subscribe((savedPatient: any) => {
      let patientIdx = savedPatient.patientIdx;

      if (patientIdx) {
   
      } else {
        patientIdx = this.patient.idx;
      }

      setTimeout(() => {
        this.patientService.setPatient(formFields, patientIdx);
        this.router.navigate(["treatment-cat"]);
      }, 3000);
    })
  }

  public scroll(destination: string): void {
    const section = document.getElementById(destination);
    if (!section)  {return; }
    section.scrollIntoView({ behavior: "smooth" });
  }

}
