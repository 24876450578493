import { Injectable } from '@angular/core';
import { NotificationMessage } from '../models/notification';
import * as fromRoot from '../store/reducers';
import { Store } from '@ngrx/store';
import * as fromNotification from "../store/actions/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private store: Store<fromRoot.State>) { }

  public send(message: string, type: string, duration: number = 5000): void {
    const date = new Date();
    const timestamp = date.getTime();
    const notification: NotificationMessage = { message, timestamp: timestamp, idx: timestamp, type, duration };
    this.store.dispatch(fromNotification.DisplayNotification({ payload: notification }));
  }
}
