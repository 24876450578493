import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppointmentService } from 'src/app/services/appointment.service';

import { PatientService } from 'src/app/services/patient.service';
import { State } from 'src/app/models/patient';
import * as fromBooking from "../../store/actions/booking";

import * as fromRoot from '../../store/reducers';
import { LoadingService } from 'src/app/services/loading.service';
import { BookingService } from 'src/app/services/booking.service';
import { Treatment } from 'src/app/models/treatment';
import { Staff } from 'src/app/models/staff';

@Component({
    selector: 'app-last-booked',
    templateUrl: './last-booked.component.html',
    styleUrls: ['./last-booked.component.scss', '../../pages/welcome/welcome.component.scss'],
    standalone: false
})
export class LastBookedComponent implements OnInit {
  public loggedIn: boolean = false;
  public loggingIn: boolean = false;
  public lastAppointment: any;
  public patientIdx: number;
  public patient: State;
  public treatments: any;
  public lastAppointmentStaff: Staff | undefined;
  public lastTreatment: any;
  public allStaff: Staff[];

  constructor(private patientService: PatientService,
    private appointmentService: AppointmentService,
    private loadingService: LoadingService,
    private bookingService: BookingService,
    private router: Router,
    private store: Store<fromRoot.State>) { }

  ngOnInit(): void {
    this.loadBookingData()
    this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => {
      this.loggedIn = loggedIn
    });
  }


  getLastAppointment() {
    this.store.select(fromRoot.getPatientDetails).subscribe((patient: State) => {
      if (patient.lastAppointment){
       // this.newBooking();
      }
      const appointment = patient.lastAppointment;
      this.lastAppointment = appointment
      if ( !this.lastTreatment){
        this.newBooking();
        return;
      }
      console.log('Last Appointment', this.lastAppointment);
      console.log("all treatments", this.treatments);
      this.lastAppointmentStaff = this.allStaff.find(staffMember => {return staffMember.idx === this.lastAppointment.chiroIdx; });
      for (let treatments of this.treatments){
        if (!this.lastTreatment){
          console.log("[match]", treatments.treatments, this.lastAppointment.treatmentIdx );
          this.lastTreatment = treatments.treatments.find(t => { return t.idx === this.lastAppointment.treatmentIdx; })
      
        }
      }

      console.log("find staff", this.lastAppointmentStaff);
      if (!this.lastAppointmentStaff){
          this.newBooking();
          return;
      }


    });
  }

  
  public loadBookingData(): void {
    this.loadingService.start();

    this.bookingService.getBookingData().subscribe(([treatments, staff]) => {
      console.log('treatments and staff: ', treatments, staff)

      this.allStaff = staff
      this.treatments = this.bookingService.filterTreatmentCategories(treatments);
      this.treatments = this.bookingService.sortStaff(this.treatments, staff);
      this.treatments = this.bookingService.updateSelectedTreatments(this.treatments);
      this.getLastAppointment();
      this.loadingService.stop();
    })
  }

  public bookSameAppointment(): void {
    if (!this.lastAppointmentStaff){ return; }
    this.store.dispatch(fromBooking.SetStaffMember({ payload: [this.lastAppointmentStaff.idx] }));

    const payload: Treatment = {
//      treatmentCategory: this.treatments.find(t => { return t.idx === this.lastAppointmentStaff.treatFromCat }).name,
      name: this.lastTreatment.name,
      idx: this.lastTreatment.idx,
      defaultDuration: this.lastTreatment.defaultDuration,
      defaultPrice: this.lastTreatment.defaultPrice,
    //  startTime: null,
  //    endTime: null
    }

    this.store.dispatch(fromBooking.SetTreatmentDetails({ payload }));
    this.router.navigate(["/appointment"]);
  }


  public newBooking() {
    this.router.navigate(["treatment-cat"]);
  }
}
