import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataService } from './data.service';
import { Staff } from '../models/staff';
import { Treatments } from '../models/treatment';




@Injectable({
    providedIn: 'root'
})

export class TreatmentsService {
    private treatmnentsStore?: Treatments[];

    constructor (private http: DataService) {}

    public getTreatmentCategories(): Observable<Treatments[]> {
        if (this.treatmnentsStore){
            return of(this.treatmnentsStore);
        }
        return this.http.get<Treatments[]>('/public/treatments').pipe(tap(v => {
            this.treatmnentsStore = v;
        }));
    }
}