<ng-container *ngIf="loggedIn">
  <div class="row details-container">
    <div class="col-sm-12 col-lg-10">
        <div class="alert alert-warning" *ngIf="newPatient">To save time on arrival at the clinic please complete your details form.</div>

        <form autocomplete="off" [formGroup]="form" class="form-horizontal" *ngIf="form">
            <mat-card>
              <mat-grid-list cols="12" rowHeight="2em">
                  <ng-container *ngFor="let question of details; let i = index">
                    <mat-grid-tile [colspan]="mobile ? '12' : question.width" [rowspan]="( question.fieldType === 'TitleArea' || question.fieldType === 'Checkbox' ) ? '2' : '3'">
                        <app-formfields [ngStyle]="{'width.%': ( question.width === 6 && !mobile ) ? 90 : 95 }" [question]="question" [form]="form"></app-formfields>
                    </mat-grid-tile>
                  </ng-container>
              </mat-grid-list>
            </mat-card>
        </form>
    </div>

    <div class="col-lg-2 mobile-margin">
        <div class="right-menu">
          <ul class="sections">
            <li *ngFor="let section of sections" >
              <button class="btn btn-link" (click)="scroll(section.fieldKey)">
                {{ section.fieldKey }}
              </button>
            </li>
          </ul>

          
            
          <div class="desktop-buttons mobile-buttons">
            <button mat-raised-button color="primary" class="mobile-button desktop-button" (click)="save()">Save</button>
          </div>

          <div class="desktop-buttons mobile-buttons">
            <button mat-raised-button color="primary" *ngIf="newPatient" class="mobile-button desktop-button" routerLink="/booking">Back to Booking</button>
          </div>
        </div>
    </div>

    <div class="mobile-save">

      <button mat-raised-button color="primary" (click)="save()">Save</button>
      <button *ngIf="newPatient" mat-raised-button color="primary" routerLink="/booking">Back to Booking</button>
    </div>
  </div>
</ng-container>