import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { FieldType, FormField } from '../models/form';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor( private formBuilder: UntypedFormBuilder ) {}

  public prepare(form: FormField[], loaded: any = null, completed: boolean = false): any {
    console.log("form", form);
  
    let fields: FormField[] = form.map((field: FormField) => {
      return {
        ...field,
        placeholder: field.label,
        required: false,
        width: field.width || 6,
        disabled: completed
      }
    })

    return this.build(fields, loaded);
  }

  private checkedValue(field: FormField, value: string): any {
    return ( field.fieldType === FieldType.Checkbox ) ? ( value === "FALSE" ? false : true ) : value;
  } 

  public build(fields: FormField[], loaded: any = null): any {
    const controls = {};
    
    fields.forEach((field: FormField) => {
        controls[field.fieldKey] = new UntypedFormControl({ value: loaded ? this.checkedValue(field, loaded[field.fieldKey]) : "", disabled: field.disabled });
    });

    console.log(controls);
    return [ this.formBuilder.group({ ...controls }), fields ];
  }
}
