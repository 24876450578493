import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { NotificationService } from './notification.service';
import { Store } from "@ngrx/store";
import * as fromRoot from '../store/reducers';
import * as fromPatient from "../store/actions/patient";
import * as fromBooking from "../store/actions/booking";
import { combineLatest, Observable } from 'rxjs';
import { initialState } from '../models/patient';

import { map, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';

export interface JWTResponse{ 
  idx: string, 
  updatedJWT: string 
}

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: DataService, 
              private config: ConfigService,
              private notification: NotificationService,
              private store: Store<fromRoot.State>) { }

  public save(formFields: any, create: boolean = true, patientIdx: number = 0): Observable<any> {
    const form = {
      form: [ formFields ],
      formIdx: 50,
      formName: "patientdetails",
      idx: create ? "0" : patientIdx,
    }

    return this.send(form);
  }

  public getPatient(): Observable<any> {
    return this.http.get("/public/patient");
  }

  private send(data: any): Observable<JWTResponse> {
    return this.http.post<JWTResponse>(`/public/patient/${data.formIdx}/${data.idx}`, data).pipe(
      tap((response) => {
        if ( response.updatedJWT ) {
          this.config.setJWT(response.updatedJWT);
        }
      }));
  }

  public setPatient(formFields: any, patientIdx: number, formRequested: boolean = false): void {
    const payload = {
      ...initialState,
      idx: patientIdx,
      FirstName: formFields.FirstName,
      LastName: formFields.LastName,
      MobilePhone: formFields.MobilePhone,
      EMail: formFields.EMail,
      NewPatient: formFields.NewPatient,
      FormRequested: formRequested,
      lastAppointment: formFields?.lastAppointment
    }; 

    this.store.dispatch(fromPatient.SetPatient({ payload }));
  }

  public clearActiveStore(): void {
    this.store.dispatch(fromPatient.InitializeAction());
    this.store.dispatch(fromBooking.InitializeAction());
  }

  public precheck(payload: any): Observable<any> {
    return this.http.post("/public/precheck/patient", payload);
  }

 
}
