import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Store } from "@ngrx/store";
import * as fromRoot from '../../store/reducers';
import { State } from 'src/app/models/patient';
import { PatientService } from 'src/app/services/patient.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', '../../pages/welcome/welcome.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {

  public emailSent: boolean = false;
  public textSent: boolean = false;
  public email = new UntypedFormControl("", [ Validators.required, Validators.email ]);
  public stage: number = 0;
  public code: string[] = ["", "", "", "", "", ""];
  public resendTimeOut: number = 30;
  private resendTimeOut$: any;

  public data = { title: `Hello! Let's get you signed in`, message: 'Please begin by entering your email address below. You will receive a one-time code either on your mobile phone or your email address.', warning: '' }

  constructor(private loginService: LoginService, 
              private router: Router, 
              private config: ConfigService,
              private patientService: PatientService, 
              private notification: NotificationService,
              private loadingService: LoadingService ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes['process'].currentValue ) {
      const value = +changes['process'].currentValue.substring(0, 1);

      if ( value === 1 ) this.login();
      if ( value === 2 ) this.loginWithCode();
    }
  }

  public login(): void {
    if ( this.email.valid ) {
      this.loadingService.start();

      this.loginService.authenticate(this.email.value).subscribe((device: any) => {
        this.loadingService.stop();

        if ( device ) {
          if ( device === "mobile phone" ) this.textSent = true;
          if ( device === "email address" ) this.emailSent = true;

          this.data.title = "We have sent you a code to enter below";
          this.data.message = "";
          this.data.warning = "";
    
          this.stage = 1;
          this.resendTimeOut = 30;
    
          if ( this.resendTimeOut$ ) {
            clearInterval(this.resendTimeOut$);
          }
    
          this.resendTimeOut$ = setInterval(() => {
            this.resendTimeOut--;
            if ( this.resendTimeOut <= 0 ) {
              clearInterval(this.resendTimeOut$);
            }
          }, 1000);
        }
      }, (error: any) => {
        console.log(error);
        this.data.warning = error.error.message ? error.error.message : 'There has been a problem logging you in please contact the clinic for assistance';
      })
    }
  }

  public loginWithCode(): void {
    this.loadingService.start();

    this.loginService.authenticate(this.email.value, this.code.join("")).subscribe((patient: State) => {
      if ( patient ) {
          if ( patient.updatedJWT ) {
            const { FirstName, LastName, MobilePhone, EMail, lastAppointment } = patient;

            this.patientService.setPatient({ FirstName, LastName, MobilePhone, EMail, lastAppointment }, patient.idx);
            this.config.setJWT(patient.updatedJWT);
            this.router.navigate(['/rebook']);
          }

          this.loadingService.stop();
      } else {
        this.stage = 0;
        this.clearCodeField();
        this.loadingService.stop();
      }
    }, (error: any) => {
      this.data.warning = 'Please enter the code again'
      this.clearCodeField();
    })
  }

  private clearCodeField(): void {
    this.code = this.code.map((input: string) => "");

    this.code.forEach((input: string, index: number) => {
      (document.getElementById(`code${index}`) as HTMLInputElement).value = "";
    })
  }

  public codeProvided(): boolean {
    return this.code.every((input: string) => input !== "");
  }

  public validInput(input: string): boolean {
    return (( +input >= 0 ) && ( +input <= 9 ));
  }

  public validateKey(event: any, input: number): void {
    const key = event.key;

    if ( !key ) return;

    const currentElement = document.getElementById(`code${input}`) as HTMLInputElement;
    const previousElement = input > 0 ? document.getElementById(`code${input - 1}`) as HTMLInputElement : null;
    const nextElement = input < 5 ? document.getElementById(`code${input + 1}`) as HTMLInputElement : null;

    if ( ( key === "Backspace" || key === "Delete" ) ) {
      if ( this.code[input] !== "" ) {
        currentElement.value = "";
        this.code[input] = "";
      } else if ( previousElement ) {
        previousElement.focus();
      }
    }

    if ( key === "Enter" ) {
      if ( this.codeProvided() ) {
        this.loginWithCode();
      }
    }

    if ( key === "ArrowLeft" ) {
      if ( previousElement ) previousElement.focus();
    }

    if ( key === "ArrowRight" ) {
      if ( nextElement ) nextElement.focus();
    }
  }

  public validateInput(event: any, input: number): void {
    const key = event.data;

    if ( !key ) return;

    const currentElement = document.getElementById(`code${input}`) as HTMLInputElement;
    const nextElement = input < 5 ? document.getElementById(`code${input + 1}`) as HTMLInputElement : null;

    if ( this.validInput(key) ) {
      this.code[input] = key;
      currentElement.value = "";
      currentElement.value = key;
      if (nextElement) nextElement.focus();
    }
  }

  public back(){
    this.router.navigate(['/']);
  }

  onPaste(e){
    console.log("[paste]",e);
    e.preventDefault();
    const clipboardData = e.clipboardData;
    if (!clipboardData){ return; }
    const pastedText = clipboardData.getData("text");
    console.log("Pasted: ", pastedText);
    if (pastedText.length == 6){
      for (let i=0; i<6; i++){
        this.code[i] = pastedText[i];
    //    (document.getElementById(`code${i}`) as HTMLInputElement).value=pastedText[i];
      }
      console.log("code", this.code);
    }
  } // 123456

}
