import { Component, HostListener, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromRoot from '../../store/reducers';
import * as fromBooking from "../../store/actions/booking";
import * as fromDate from "../../store/actions/date";
import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { Subscription, first, map } from 'rxjs';
import { initialState, State } from 'src/app/models/patient';
import { Store } from '@ngrx/store';
import { Treatment, Treatments } from 'src/app/models/treatment';
import { TreatmentsService } from 'src/app/services/treatments.service';
import { StaffService } from 'src/app/services/staff.service';
import { Staff } from 'src/app/models/staff';

@Component({
    selector: "app-staff",
    templateUrl: "./staff.component.html",
    styleUrls: ["./staff.component.scss"],
    animations: [
        trigger("slideInOut", [
            transition(":enter", [
                style({ height: "0", opacity: 0 }),
                animate("300ms ease-in-out", style({ height: "*", opacity: 1 })),
            ]),
            transition(":leave", [
                animate("300ms ease-in-out", style({ height: "0", opacity: 0 })),
            ]),
        ]),
    ],
    standalone: false
})
export class StaffComponent implements OnInit {
  public readonly companyName = "attend2health";
  public treatments: any = [];
  public mobile: boolean;
  public availabilities: any;
  public appointments: any;
  public selectedClinic$: Subscription;
  public selectedClinic: any;
  public selectedStaff: any = null;
  public loggedIn: boolean;
  public patient: any;
  public selectedTreatmentCat;
  public selectedTreatment?: Treatments;
  public staffList: Staff[];
  public selectedTreatmentIdx: number;

  constructor(
    public bookingService: BookingService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private configService: ConfigService,
    private loadingService: LoadingService,
    private treatmentService: TreatmentsService,
    private staffSerive: StaffService
  ) {
    this.store
      .select(fromRoot.getPatientDetails)
      .subscribe((patient: State) => (this.patient = patient));
  
    this.route.params.subscribe(params => {
      this.selectedTreatmentCat = params['treatmentCat'];
      this.selectedTreatmentIdx = params['treatmentIdx'];

      this.treatmentService.getTreatmentCategories().pipe(map(v=>{
        return v.find(v=>v.idx==this.selectedTreatmentCat);
      }
      )).subscribe(res=>{
        this.selectedTreatment = res;
        this.getStaffList();
      });
  
    });
  }

  ngOnInit(): void {
    this.loadBookingData();


    this.selectedClinic$ = this.configService
      .getStoredClinicDetails()
      .subscribe((clinic: any) => {
        if (clinic && clinic.idx) {
          this.selectedClinic = clinic;

          this.configService
            .getDiarySettings(clinic.idx)
            .subscribe((settings: any) => {
              this.availabilities = settings.availability;
            });
        }
      });

    this.store
      .select(fromRoot.isPatientLoggedIn)
      .subscribe((loggedIn: boolean) => {
        this.loggedIn = loggedIn;
        this.loadBookingData();
        console.log(this.loggedIn);
      });
      // clear stored date so that if user goes back it resets to todays date
      this.store.dispatch(fromDate.clearSelectedDate());
  }

  ngOnDestroy(): void {
 
  }

  private getStaffList(){
    if (!this.selectedTreatment){ return; }

    this.staffSerive.onlyStaffWithTreatments(this.selectedTreatment.treatments).subscribe(res=>{
      console.log("got staff ", res);
      this.staffList = res;
    });

  }


  public loadBookingData(): void {
    this.loadingService.start();

    this.bookingService.getBookingData().subscribe(([treatments, staff]) => {
      console.log(treatments, staff);
      this.treatments =
        this.bookingService.filterTreatmentCategories(treatments);
      this.treatments = this.bookingService.sortStaff(this.treatments, staff);
      this.treatments = this.bookingService.updateSelectedTreatments(
        this.treatments
      );
/*
      // if patient is New only display treatments for new patients
      if (this.patient.NewPatient) {
        this.treatments.forEach((group) => {
          group.treatments.forEach((treatment, index) => {
            if (treatment.name !== "New Patient") {
              group.treatments.splice(index, 1);
            }
          });
        });
      }
      console.log(this.treatments);
*/
      this.loadingService.stop();
    });
  }

  public selectStaff(event: any, staff: any, treatment: any): void {
    this.staffSeletionDone ([staff.idx], treatment);
  }
  
  public selectAllStaffForTreatment(treatment){
    console.log("[set]", treatment.staff);
    this.staffSeletionDone(treatment.staff.map(s=>(s.idx)), treatment.idx);
  }

  public staffSeletionDone(selectedStaff: number[], treatmentTypeIdx: number){
    console.log("[set]", this.selectedTreatment);
    this.store.dispatch(
      fromBooking.SetTreatmentAndStaff({ payload:{ staff: selectedStaff, treatmentIdx: this.selectedTreatmentIdx, treatmentTypeIdx: treatmentTypeIdx }})
    );

    if (this.selectedTreatmentIdx>0){
      const treatment = this.selectedTreatment?.treatments.find(t => (t.idx == this.selectedTreatmentIdx));
      if (treatment){
        this.store.dispatch(fromBooking.SetTreatmentDetails({payload: treatment}));
        this.router.navigate(["appointment"]);
        return;
      }
    }
    this.router.navigate(['treatment']);
  }

  back(){
    this.router.navigate(['treatment-cat']);
  }


}
