<footer class="w-100">
    <div class="d-flex justify-content-between w-100 px-4" *ngIf="links">
        <div *ngIf="links.privacyPolicy">
            <a [href]="links.privacyPolicy" target="_blank">Privacy</a>
        </div>
        <div *ngIf="links.cancellationPolicy">
            <a [href]="links.cancellationPolicy" target="_blank">Cancellation Policy</a>
        </div>
        <div *ngIf="links.contactUs">
            <a [href]="links.contactUs" target="_blank">Contact Us</a>
        </div>
    </div>
</footer>


