export interface FormField {
    idx?: number;
    fieldType: FieldType;
    fieldKey: string;
    label: string;
    required: boolean;
    width?: number;
    placeholder?: string;
    type?: string;
    options?: any[];
    disabled?: boolean;
}

export const enum FieldType {
    DropdownQuestion = "DropdownQuestion",
    RangeSlider = "RangeSlider",
    DatePicker = "DatePicker",
    TextboxQuestion = "TextboxQuestion",
    InfoBox = "InfoBox",
    TextArea = "TextArea",
    Checkbox = "Checkbox",
    GPchooser = "GPchooser",
    Vendorchooser = "Vendorchooser",
    Patientchooser = "Patientchooser",
    FileUploader = "FileUploader",
    TitleArea = "TitleArea",
    SubTitleArea = "SubTitleArea"
}

export const onlineDetails: FormField[] = [
    { fieldType: FieldType.TitleArea, label:'', fieldKey: "Personal Details", width: 12, required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Title", label: "Title", type: "text" , required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "FirstName", label: "First Name", type: "text" , required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "MiddleName", label: "Middle Name", type: "text" , required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "LastName", label: "Last Name", type: "text" , required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Address1", label: "Address 1", type: "text" , required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Address2", label: "Address 2", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Address3", label: "Address 3", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Town", label: "Town", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "County", label: "County", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "PostCode", label: "Post Code", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "HomePhone", label: "Home Phone", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "MobilePhone", label: "Mobile Phone", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "WorkPhone", label: "Work Phone", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "EMail", label: "E-Mail", type: "email", required: false },
    { fieldType: FieldType.DropdownQuestion, fieldKey: "sex", label: "Sex", type: "static", options: ["Male", "Female", "Intersex", "MtF Female", "FtM Male"], width: 12, required: false },
    { fieldType: FieldType.DatePicker, fieldKey: "DOB", label: "DOB (dd/mm/yyyy)", type: "text", width: 12, required: false },
    { fieldType: FieldType.DropdownQuestion, fieldKey: "MaritalStatus", label: "Marital Status", type: "static", options: ["Single", "Married", "Widow"], width: 12, required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "NumberChildren", label: "Number of Children", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "Occupation", label: "Occupation", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "nextofkin", label: "Next of Kin", type: "text", required: false },
    { fieldType: FieldType.TextboxQuestion, fieldKey: "contact", label: "Contact Details", type: "text", required: false },
    { fieldType: FieldType.DropdownQuestion, fieldKey: "HearAboutUsIdx", label: "How did you hear about us", type: "static", options: [], width: 12, required: false },
    { fieldType: FieldType.Checkbox, fieldKey: "mailshot", label: "Email Appointment Reminders", type: "static", width: 12, required: false },
    { fieldType: FieldType.Checkbox, fieldKey: "sms", label: "Text Appointment Reminders", type: "static", width: 12, required: false },
    { fieldType: FieldType.Checkbox, fieldKey: "ConsentMarketing", label: "Would you like to hear about the latest news and offers.", type: "static", width: 12, required: false },
    { fieldType: FieldType.TitleArea, label:'', fieldKey: "GP Details", width: 12, required: false },
    { fieldType: FieldType.GPchooser, fieldKey: "GPIdx", label: "GP", type: "dynamic", width: 12, idx: 539, required: false },
    { fieldType: FieldType.InfoBox, label:'', fieldKey: "Please inform the reception if you have medical insurance when arriving at the clinic.", width: 12, required: false },
  ]
